.fake-textarea {
    width: 100%;
    height: 7em;
    background-color: white;
    color: gray;
}

[contenteditable=true]:empty:not(focus):before {
    content: attr(placeholder);
    pointer-events: none;
    display: block;
}

/* example:
// <div id="pgnfen" className="fake-textarea" 
//     contentEditable="true"
//     onChange={() => {}}
//     onPaste={(e) => handleInput(e)}
//     placeholder="Move, or paste FEN/PGN here"
//     spellCheck="false"
// > 
//    {text && <pre style={{marginLeft: "0", textAlign:"left", color:"black", fontSize:"10pt"}}>{text}</pre>}
// </div>
*/

#fenpgn {
    width: 100%;
    height: 200%;
    background-color: white;
    font-size: 8pt;
}