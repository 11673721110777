div.about {
    text-align: left;
    color: white;
    margin: 1em;
    padding-bottom: 2.5em;
}

h2 {
    font-family: 'Cinzel', serif;
}

h3 {
    text-indent: 1.5em;
}

pre {
    margin-left: 3em;
}

a {
    color: limegreen;
}

p {
    font-size: 14pt;
    font-family: 'Inter Tight', sans-serif;
    margin: 1.5rem;
}

h3 + p {
    margin: 2rem;
}

.image {
    max-width: 80%;
    border: 5px solid darkkhaki;
    margin-left: 10%;
    margin-top: 1rem;
}