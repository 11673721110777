#listItem {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr 1fr;
    padding: 3px;
}

#transitionsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 3px;
}