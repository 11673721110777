html {
  height: 100%;
  /* background: url("../public/resources/background.jpg") no-repeat center fixed; */
  background-color: rgb(75, 81, 87);
  background-size: cover;
}

.App {
  text-align: center;
}

.App-title {
  font-size: xx-large;
  font-weight: bold;
  margin: 0.5em;
  margin-bottom: 0px;
}

.App-header {
  background-color: rgb(54, 74, 56);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
}

.App-subheading {
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin: 0.5em;
  margin-top: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.font-cinzel {
  font-family: 'Cinzel', serif;
}

.font-shadow {
  text-shadow: 1px 1px 0px black;
}

p,
td,
th,
.white {
  color: white
}

.underline {
  text-decoration: underline;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.centered-sm {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -190%);
}

.centered-table,
th,
td {
  border: 2px solid white;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: brightness(0.7);
  border-collapse: collapse;
}

/* Not sure where these originally came from: */

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}


.padded {
  margin: 10px
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 20px;
}

.double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.menubar {
  background-color: #585C5C;
}

.menuitem {
  border-radius: 3em / 1em;
  height: 3em;
  background: radial-gradient(#3d8050, #585C5C);
}

.menuitem>span,
.menuitem>label {
  color: #FFFFFF;
  font-weight: bold;
}

.menuitem>span {
  margin-block: auto;
}

.menuitem.selected>span {
  color: #4FD0DF;
  font-weight: bold;
}

.fakeLink {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: medium;
  border: none;
  color: rgb(204, 242, 225);
  cursor: pointer;
  display: flex;
}

.fakeLink:hover {
  text-decoration: underline;
}

.scrollableList {
  height: 75%;
  overflow: hidden;
  overflow-y: scroll;
  color: aquamarine;
  border: 2px solid;
  scrollbar-color: lightblue;
  backdrop-filter: brightness(0.7);
}

/* width */
.scrollableList::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.scrollableList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scrollableList::-webkit-scrollbar-thumb {
  background: rgb(17, 116, 4);
  border-radius: 5px;
}

/* Handle on hover */
.scrollableList::-webkit-scrollbar-thumb:hover {
  background: #068513;
}

.sites {
  margin-left: "2em"
}

.sitesColor {
  color: rgb(158, 186, 110);
  font-weight: bolder;
  font-size: large;
}

.player {
  color: white;
  backdrop-filter: brightness(0.7);
}

.darkBackground {
  backdrop-filter: brightness(0.7);
}

div.scrollable {
  display: flex;
  overflow-x: scroll;
  max-width: 100%;
  white-space: nowrap;
}

div.scrollableY {
  flex: 0 0 300px;
  overflow: auto;
  white-space: nowrap;
}

.scrollable, 
.scrollableY::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.scrollable, 
.scrollableY::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: green;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}