/* Style the button that is used to open and close the collapsible content */
.collapsible {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active, .collapsible:hover {
    background-color: #ccc;
  }
  
  /* Style the collapsible content. Note: hidden by default */
  .content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background: radial-gradient(#3d8050, #585C5C);
    text-align: left;
  }
